import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import NeoApp from './NeoApp.tsx'
import './index.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Suspense>
    <NeoApp />
  </Suspense>,
)
