import Routerx from '@/routerx'
import { App, ConfigProvider } from 'antd'
import zhCN from 'antd/locale/zh_CN'
import 'dayjs/locale/zh-cn'
import { RouterProvider } from 'react-router-dom'

function NeoApp() {
  return (
    <div className="App">
      <App>
        <ConfigProvider
          locale={zhCN}
          theme={{
            token: {
              borderRadius: 5,
            },
            // algorithm: theme.darkAlgorithm,
            // algorithm: theme.compactAlgorithm,
            components: {
              // Layout: {
              //   bodyBg: '#f8f8f8',
              // },
              // Menu: {
              //   /* 这里是你的组件 token */
              //   itemColor: 'rgba(0, 0, 0, 0.65);',
              //   itemSelectedColor: 'rgba(0, 0, 0, 0.95);',
              //   itemSelectedBg: '#e9e9e9',
              // },
            },
          }}
        >
          <RouterProvider router={Routerx} />
        </ConfigProvider>
      </App>
    </div>
  )
}

export default NeoApp
